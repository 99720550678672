import routes from '@root/routes';
import api from '@shared/api';
import { FormValues } from './Form';

const submitTourbookExternalListing = async ({
  values,
  tourbookId,
  externalListingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  values: Partial<FormValues>;
  tourbookId: string;
  externalListingId?: string;
  onSuccess?: Function;
  onError?: Function;
}) => {
  const body = {
    ...values,
    // necessary because the default snake casing of addressLine1 is address_line1
    address_line_1: values.addressLine1,
    address_line_2: values.addressLine2,
    photos: values.photos?.map(i => ({
      cloudinaryId: i.cloudinaryId,
      floorPlan: i.description.toLowerCase() === 'floor plan',
    })),
    askingRentUnits: `${values.countryCode === 'GB' ? 'Gbp' : 'Usd'}/${
      values.askingRentUnitsWithoutCurrency
    }`,
    askingRent: values.askingRentUseRange ? (values.minAskingRent ?? 0) : values.minAskingRent,
    minAskingRent: values.askingRentUseRange ? (values.minAskingRent ?? 0) : values.minAskingRent,
    maxAskingRent:
      values.askingRentUseRange && values.maxAskingRent !== values.minAskingRent
        ? values.maxAskingRent
        : null,
  };

  const response = externalListingId
    ? await api.put(routes.api.tourbookExternalListing({ tourbookId, externalListingId }), body)
    : await api.post(routes.api.tourbookExternalListings(tourbookId), body);

  if (response.ok) {
    onSuccess(response);
  } else {
    onError(response);
  }
};

export default submitTourbookExternalListing;
