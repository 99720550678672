import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { uniqWith, isEqual, throttle } from 'lodash';
import { update } from '@shared/typedApi';
import { Form } from '@components/shared/forms';
import { Notification } from '@components/shared';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import { AdminMicrosite, MicrositePageSlugs } from '@root/types';
import cn from 'classnames';
import FormFields from './FormFields';
import { isPageVisible, isSinglePageLayout, micrositeFormValidationSchema } from './utils';

type Props = {
  buildingId: string;
  microsite: AdminMicrosite;
  refetch: () => void;
  hasVideo: boolean;
};

export type PreviewSection = { page: MicrositePageSlugs; element: string };

const fetchGoogleSiteVerificationContent = (html: string | null) => {
  if (html === null || html === undefined || html === '') return null;

  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  const metaTag = tempElement.querySelector('meta[name="google-site-verification"]');
  if (metaTag) {
    const contentValue = metaTag.getAttribute('content');
    return contentValue;
  }

  return null;
};

const initialGoogleSiteVerificationTag = (code: string | null) => {
  if (!code) return '';

  return `<meta name='google-site-verification' content=${code} />`;
};

export default function EditMicrosite({ microsite, buildingId, refetch, hasVideo }: Props) {
  const { t } = useTranslation('admin');

  const mutation = useMutation(
    (form: Partial<AdminMicrosite>) => {
      const {
        topNavLogo,
        footerLogo,
        favicon,
        genericContentBlocks,
        googleSitesVerificationCode,
        ...rest
      } = form;

      return update(routes.api.admin.buildingMicrosite(buildingId), {
        ...rest,
        topNavLogoAttributes: topNavLogo,
        footerLogoAttributes: footerLogo,
        faviconAttributes: favicon,
        genericContentBlocksAttributes: JSON.stringify(genericContentBlocks),
        googleSitesVerificationCode: googleSitesVerificationCode
          ? fetchGoogleSiteVerificationContent(googleSitesVerificationCode)
          : null,
      });
    },
    {
      onSuccess: () => {
        Notification.success({
          title: t('common:changesSaved'),
          placement: 'topRight',
        });
        refetch();
      },
      onError: (error: Error) => {
        Notification.error({
          title: t('common:somethingWentWrong'),
          text: JSON.parse(error.message).errors[0].message,
          placement: 'topRight',
        });
      },
    },
  );

  const [iframeNavigationCount, setIframeNavigationCount] = useState(0);
  const [previewSection, setPreviewSection] = useState<PreviewSection>({
    page: 'home',
    element: '',
  });

  const { page: previewSectionPage, element: previewSectionElement } = previewSection;
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      const { src } = e.data;
      if (!src) return;
      window.removeEventListener('message', handleMessage);
      if (e.data.page === previewSectionPage) return;
      if (src === 'microsite-preview-iframe') {
        setPreviewSection({ page: e.data.page, element: '' });
      }
    };
    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [previewSectionPage]);

  useEffect(() => {
    if (previewSectionElement) {
      const iframeElements = document.getElementsByClassName('microsite-preview-iframe');
      const lastIframeElement = iframeElements[iframeElements.length - 1] as HTMLIFrameElement;
      lastIframeElement.contentWindow?.postMessage(
        { type: 'GO_TO_ELEMENT', element: previewSectionElement },
        '*',
      );
    }
  }, [previewSectionElement, iframeNavigationCount]);

  return (
    <Form
      id="edit-microsite"
      layout="admin"
      initialValues={{
        layout: microsite.layout,
        font: microsite.font,
        headerBackgroundColor: microsite.headerBackgroundColor,
        headerTextColor: microsite.headerTextColor,
        headerBorderColor: microsite.headerBorderColor,
        hasBrochure: microsite.hasBrochure,
        brochureButtonColor: microsite.brochureButtonColor,
        brochureButtonTextColor: microsite.brochureButtonTextColor,
        homeBuildingInfoBackgroundColor: microsite.homeBuildingInfoBackgroundColor,
        homeBuildingInfoTextColor: microsite.homeBuildingInfoTextColor,
        homeBuildingInfoBorderColor: microsite.homeBuildingInfoBorderColor,
        homeBuildingInfoDescriptionPlacement: microsite.homeBuildingInfoDescriptionPlacement,
        homeBuildingInfoDescriptionTextSizeClassname:
          microsite.homeBuildingInfoDescriptionTextSizeClassname,
        homeBuildingInfoFactsSize: microsite.homeBuildingInfoFactsSize,
        homeBuildingInfoFactsColumnCount: microsite.homeBuildingInfoFactsColumnCount.toString(),
        homeBuildingInfoSectionIsVisible: microsite.homeBuildingInfoSectionIsVisible,
        homeBuildingInfoSectionTitle: microsite.homeBuildingInfoSectionTitle,
        homeAddressTextPlacement: microsite.homeAddressTextPlacement,
        homeHeroImageStyle: microsite.homeHeroImageStyle,
        homeGoogleMapSectionIsVisible: microsite.homeGoogleMapSectionIsVisible,
        homeGoogleMapSectionBackgroundColor: microsite.homeGoogleMapSectionBackgroundColor,
        homeGoogleMapSectionTextColor: microsite.homeGoogleMapSectionTextColor,
        homeGoogleMapSectionTitle: microsite.homeGoogleMapSectionTitle || '',
        footerBackgroundColor: microsite.footerBackgroundColor,
        footerTextColor: microsite.footerTextColor,
        footerBorderColor: microsite.footerBorderColor,
        availabilityStyle: microsite.availabilityStyle,
        availabilityBorderColor: microsite.availabilityBorderColor,
        availabilityButtonColor: microsite.availabilityButtonColor,
        availabilityButtonTextColor: microsite.availabilityButtonTextColor,
        availabilityBackgroundColor: microsite.availabilityBackgroundColor,
        availabilityTextColor: microsite.availabilityTextColor,
        availabilityIconAndLinkColor: microsite.availabilityIconAndLinkColor,
        availabilitySectionIsVisible: microsite.availabilitySectionIsVisible,
        availabilityTitle: microsite.availabilityTitle,
        galleryBackgroundColor: microsite.galleryBackgroundColor,
        galleryTitle: microsite.galleryTitle,
        galleryTextColor: microsite.galleryTextColor,
        status: microsite.status,
        linkedinUrl: microsite.linkedinUrl || '',
        twitterUrl: microsite.twitterUrl || '',
        facebookUrl: microsite.facebookUrl || '',
        instagramUrl: microsite.instagramUrl || '',
        tiktokUrl: microsite.tiktokUrl || '',
        footerLeftText: microsite.footerLeftText || '',
        footerRightText: microsite.footerRightText || '',
        contactsBackgroundColor: microsite.contactsBackgroundColor,
        contactsBorderColor: microsite.contactsBorderColor,
        contactsSectionIsVisible: microsite.contactsSectionIsVisible,
        contactsTextColor: microsite.contactsTextColor,
        contactsTitle: microsite.contactsTitle,
        amenitiesBackgroundColor: microsite.amenitiesBackgroundColor,
        amenitiesSectionIsVisible: microsite.amenitiesSectionIsVisible,
        amenitiesTextColor: microsite.amenitiesTextColor,
        amenitiesTitle: microsite.amenitiesTitle || '',
        certificationsTitle: microsite.certificationsTitle || '',
        certificationsBackgroundColor: microsite.certificationsBackgroundColor,
        certificationsTextColor: microsite.certificationsTextColor,
        certificationsBorderColor: microsite.certificationsBorderColor,
        certificationsSectionIsVisible: microsite.certificationsSectionIsVisible,
        tenantPortalUrl: microsite.tenantPortalUrl || '',
        topNavLogo: microsite.topNavLogo,
        footerLogo: microsite.footerLogo,
        footerLogoLink: microsite.footerLogoLink || '',
        genericContentBlocks: microsite.genericContentBlocks,
        favicon: microsite.favicon,
        isDemo: microsite.isDemo,
        homeAddressBackgroundColor: microsite.homeAddressBackgroundColor,
        homeAddressTextColor: microsite.homeAddressTextColor,
        homeAddressBlurAmount: microsite.homeAddressBlurAmount,
        homeHeroBackgroundOverlay: microsite.homeHeroBackgroundOverlay,
        homeHeroBackgroundOverlayColor: microsite.homeHeroBackgroundOverlayColor,
        usesCustomAddressText: microsite.usesCustomAddressText,
        customAddressHtml: microsite.customAddressHtml,
        pages: microsite.pages,
        contentBlocks: microsite.contentBlocks,
        sectionFraming: microsite.sectionFraming.toLocaleString(),
        sectionFramingColor: microsite.sectionFramingColor,
        googleSitesVerificationCode: initialGoogleSiteVerificationTag(
          microsite.googleSitesVerificationCode,
        ),
        gallerySectionIsVisible: microsite.gallerySectionIsVisible,
      }}
      onSubmit={(values, { resetForm }) => {
        const unchangedCustomAddressHtml =
          values.customAddressHtml === microsite.defaultAddressHtml;
        mutation.mutate(
          {
            ...values,
            sectionFraming: values.sectionFraming === 'true',
            customAddressHtml: unchangedCustomAddressHtml ? null : values.customAddressHtml,
            homeBuildingInfoFactsColumnCount: parseInt(values.homeBuildingInfoFactsColumnCount, 10),
          },
          {
            onSuccess: () => {
              resetForm({ values });
            },
          },
        );
      }}
      validationSchema={micrositeFormValidationSchema(t)}
      className="relative h-[calc(100vh_-_320px)] w-full"
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 grid grid-cols-[424px_720px]">
        <div data-testid="form" className="flex flex-col overflow-scroll pr-3 pl-3 ml-[-24px]">
          <FormFields
            sendPreviewTo={args => {
              setPreviewSection({ ...previewSection, ...args });
              setIframeNavigationCount(c => c + 1);
            }}
            microsite={microsite}
            hasVideo={hasVideo}
            buildingId={buildingId}
            refetch={refetch}
          />
        </div>
        <div>
          <IframePreview
            page={previewSection.page}
            element={previewSection.element}
            srcPath={microsite.previewUrl}
            buildingId={buildingId}
            micrositeId={microsite.id}
            navCount={iframeNavigationCount}
          />
        </div>
      </div>
    </Form>
  );
}

type IframeInstance = {
  src: string;
  navCount: number;
  pageString?: string;
  previewId?: string;
};
function IframePreview({ srcPath, page, element, buildingId, micrositeId, navCount }) {
  const { values, dirty } = useFormikContext<AdminMicrosite>();
  let pageString = isSinglePageLayout(values.layout) || page === 'home' ? '/' : `/${page}`;
  const elementString = element ? `#${element}` : '';

  const pageVisible = isPageVisible(values, page);
  pageString = pageVisible ? pageString : '/';

  const [iframeCount, setIframeCount] = useState<number>(navCount);
  const [previewId, setPreviewId] = useState<string | null>(null);

  const mutation = useMutation(
    (form: Partial<AdminMicrosite> & { micrositeId: string }) => {
      const {
        topNavLogo,
        footerLogo,
        favicon,
        genericContentBlocks,
        contentBlocks,
        googleSitesVerificationCode,
        ...rest
      } = form;

      return update(routes.api.admin.buildingMicrositePreview(buildingId), {
        ...rest,
        topNavLogo,
        footerLogo,
        faviconAttributes: favicon,
        contentBlockAttributes: contentBlocks,
        googleSitesVerificationCode: googleSitesVerificationCode
          ? fetchGoogleSiteVerificationContent(googleSitesVerificationCode)
          : null,
        genericContentBlocksAttributes: genericContentBlocks?.map(gcb => {
          return {
            ...gcb,
            cloudinary_image_attributes: gcb.image
              ? {
                  cloudinaryId: gcb.image.cloudinaryId,
                  description: gcb.image.description,
                  transformations: gcb.image.transformations,
                  cropperCanvasData: gcb.image.cropperCanvasData,
                }
              : null,
          };
        }),
        iframe: 'true',
      });
    },
    {
      onSuccess: result => {
        setPreviewId(result.id);
        setIframeCount(iframeCount + 1);
      },
      onError: () => {},
    },
  );

  const { isLoading } = mutation;

  const src = `${srcPath}${pageString}?preview_id=${previewId}${elementString}`;

  const { mutateAsync } = mutation;
  const throttledMicrositePreview = useMemo(
    () =>
      throttle(async formValues => {
        mutateAsync({ ...formValues, micrositeId });
      }, 1000),
    [mutateAsync, micrositeId],
  );

  useEffect(() => {
    return () => throttledMicrositePreview.cancel();
  }, [throttledMicrositePreview]);

  useEffect(() => {
    throttledMicrositePreview({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const [iframeInstances, setIframeInstances] = useState<IframeInstance[]>([
    { src, navCount: iframeCount },
  ]);
  useEffect(() => {
    if (!previewId || isLoading) return;

    const latestIframeInstance = iframeInstances[iframeInstances.length - 1];
    if (
      latestIframeInstance?.pageString !== pageString ||
      latestIframeInstance?.previewId !== previewId ||
      iframeCount !== latestIframeInstance.navCount
    ) {
      const newIframeInstance = {
        src,
        navCount: iframeCount,
        pageString,
        previewId,
      };
      setIframeInstances(instances => uniqWith([instances[0], newIframeInstance], isEqual));
    }
  }, [previewId, iframeInstances, navCount, iframeCount, isLoading, pageString, src]);

  const extraSpaceForSaveButtonBar = dirty && 'pb-3';

  return (
    <>
      {iframeInstances.map((iframeInstance, i) => (
        <iframe
          key={iframeInstance.src + iframeInstance.navCount}
          data-testid="microsite-preview-iframe"
          title="microsite-preview"
          src={iframeInstance.src}
          className={cn(
            'microsite-preview-iframe',
            'absolute h-[200%] w-[1440px] origin-top-left scale-50',
            extraSpaceForSaveButtonBar,
          )}
          style={{ zIndex: -i }}
          onLoad={() => {
            setIframeInstances([...iframeInstances.slice(i)]);
          }}
        />
      ))}
    </>
  );
}
