import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { AdminMicrosite } from '@root/types';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { Checkbox } from '@viewthespace/components';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import { isSinglePageLayout, useMicrositeFormContext } from '../../utils';
import ColorPicker from '../../ColorPicker';
import TextColorSelector from '../../TextColorSelector';

export default function Gallery({ id, index, anchorSlug }: ContentBlockProps) {
  const { colorGroups, changeFontColorWithColorChange, sendPreviewTo, resetKey, microsite } =
    useMicrositeFormContext();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();
  const isGallerySectionHidden = !values.gallerySectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      index={index}
      title={t('microsite.sections.gallery')}
      section="gallery"
      onClick={() =>
        sendPreviewTo({
          page: isSinglePageLayout(values.layout!) ? 'home' : 'gallery',
          element: anchorSlug,
        })
      }
      isHidden={isGallerySectionHidden}
    >
      {flags['market-office.hide-gallery-contact-leasing'] ? (
        <Checkbox
          label={t('microsite.hideSection')}
          className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
          backgroundStyle="filled"
          isChecked={isGallerySectionHidden}
          onChange={e => {
            setFieldValue('gallerySectionIsVisible', !e.value);
            setFieldTouched('gallerySectionIsVisible', true);
          }}
        />
      ) : null}
      <ColorPicker
        colorGroups={colorGroups}
        name="galleryBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        onChange={changeFontColorWithColorChange('galleryTextColor')}
      />
      {flags['market-office.text-color-selector'] && <TextColorSelector name="galleryTextColor" />}
      <RichTextEditorInput
        name="galleryTitle"
        labelText={t('microsite.sectionTitle')}
        hideLabel
        editorClassName="min-h-[100px] bg-white"
        containerClass="!mb-0"
        excludeMenuItems={['bulletList']}
        initialContent={microsite.galleryTitle}
        defaultTextSize="header32"
        resetKey={resetKey}
        fast
        shouldDebounce
      />
    </ContentBlockWrapper>
  );
}
