import { TextInput } from '@components/shared/forms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { useFormikContext } from 'formik';
import { AdminMicrosite } from '@root/types';
import { Checkbox } from '@viewthespace/components';
import TextColorSelector from '../../TextColorSelector';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import { isSinglePageLayout, useMicrositeFormContext } from '../../utils';
import ColorPicker from '../../ColorPicker';

export default function Contact({ id, index, anchorSlug }: ContentBlockProps) {
  const { colorGroups, resetKey, microsite, changeFontColorWithColorChange, sendPreviewTo } =
    useMicrositeFormContext();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();

  const sectionTitlesAreRichText = flags['market.building-descriptions-support-rich-text'];
  const isContactSectionHidden = !values.contactsSectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.leasingContacts')
          : t('microsite.sections.contactLeasingContacts')
      }
      section="contactLeasingContacts"
      onClick={() =>
        sendPreviewTo({
          page: isSinglePageLayout(values.layout!) ? 'home' : 'contact',
          element: anchorSlug,
        })
      }
      index={index}
      isHidden={isContactSectionHidden}
    >
      {flags['market-office.hide-gallery-contact-leasing'] ? (
        <Checkbox
          label={t('microsite.hideSection')}
          className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
          backgroundStyle="filled"
          isChecked={isContactSectionHidden}
          onChange={e => {
            setFieldValue('contactsSectionIsVisible', !e.value);
            setFieldTouched('contactsSectionIsVisible', true);
          }}
        />
      ) : null}
      <ColorPicker
        colorGroups={colorGroups}
        name="contactsBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        data-testid="contact-background-color-picker"
        onChange={changeFontColorWithColorChange('contactsTextColor')}
      />
      {flags['market-office.text-color-selector'] && <TextColorSelector name="contactsTextColor" />}
      <ColorPicker
        colorGroups={colorGroups}
        name="contactsBorderColor"
        labelText={t('microsite.borderColor')}
        hasTransparentOption
      />
      {sectionTitlesAreRichText ? (
        <RichTextEditorInput
          name="contactsTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          excludeMenuItems={['bulletList']}
          initialContent={microsite.contactsTitle}
          defaultTextSize="header32"
          resetKey={resetKey}
          fast
          shouldDebounce
        />
      ) : (
        <TextInput name="contactsTitle" labelText={t('microsite.sectionTitle')} />
      )}
    </ContentBlockWrapper>
  );
}
