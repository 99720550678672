import { TerraSelect, RadioGroup, SliderInput, Toggle } from '@components/shared/forms';
import { AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { InlineNotice } from '@viewthespace/components';
import InfoTooltip from '@components/shared/InfoTooltip';
import BackgroundOverlayField from './BackgroundOverlayField';
import HexAlphaColorPicker from './HexAlphaColorPicker';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import { useMicrositeFormContext } from '../../utils';

export default function Header({ id, index, anchorSlug }: ContentBlockProps) {
  const { values } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { colorGroups, hasVideo, sendPreviewTo, changeFontColorWithColorChange, resetKey } =
    useMicrositeFormContext();

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      index={index}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.header')
          : t('microsite.sections.homeHeader')
      }
      section="homeHeader"
      onClick={() => sendPreviewTo({ page: 'home', element: anchorSlug })}
    >
      <RadioGroup
        name="homeHeroImageStyle"
        options={[
          { value: 'single-image', label: t('microsite.homeHeroImageStyle.singleImage') },
          { value: 'video', label: t('microsite.homeHeroImageStyle.video'), disabled: !hasVideo },
        ]}
        labelText={t('microsite.homeHeroImageStyle.title')}
        data-testid="home-hero-image-style-selector"
        optionsInRow
        fast
      />
      <TerraSelect
        options={[
          { value: 'top_left', label: t('microsite.homeAddressTextPlacement.topLeft') },
          { value: 'top_middle', label: t('microsite.homeAddressTextPlacement.topMiddle') },
          {
            value: 'top_right',
            label: t('microsite.homeAddressTextPlacement.topRight'),
          },
          {
            value: 'middle_left',
            label: t('microsite.homeAddressTextPlacement.middleLeft'),
          },
          {
            value: 'middle_middle',
            label: t('microsite.homeAddressTextPlacement.middleMiddle'),
          },
          {
            value: 'middle_right',
            label: t('microsite.homeAddressTextPlacement.middleRight'),
          },
          { value: 'bottom_left', label: t('microsite.homeAddressTextPlacement.bottomLeft') },
          {
            value: 'bottom_middle',
            label: t('microsite.homeAddressTextPlacement.bottomMiddle'),
          },
          {
            value: 'bottom_right',
            label: t('microsite.homeAddressTextPlacement.bottomRight'),
          },
        ]}
        name="homeAddressTextPlacement"
        labelText={t('microsite.homeAddressTextPlacement.title')}
        data-testid="home-address-text-placement-selector"
        isClearable={false}
        warningText={
          values.homeAddressTextPlacement === 'middle_middle' ? (
            <div className="font-body-small text-text-subdued mt-1">
              {t('microsite.homeAddressTextPlacement.warningText')}
            </div>
          ) : undefined
        }
      />
      <RadioGroup
        options={[
          { value: '#000000', label: t('microsite.colors.black') },
          { value: '#ffffff', label: t('microsite.colors.white') },
        ]}
        name="homeAddressTextColor"
        labelText={t('microsite.textColor')}
        optionsInRow
        fast
      />
      <HexAlphaColorPicker
        name="homeAddressBackgroundColor"
        labelText={t('microsite.homeAddressBackgroundColor.title')}
        colorGroups={colorGroups}
        onChange={changeFontColorWithColorChange('homeAddressTextColor')}
      />
      <SliderInput
        name="homeAddressBlurAmount"
        labelText={
          <div className="flex gap-1 items-center">
            {t('microsite.titleCardBackgroundBlur')}
            {values.homeHeroImageStyle === 'video' ? (
              <InfoTooltip
                data-testid="video-blur-info-tooltip"
                tooltipText={t('microsite.videoBlurWarning')}
              />
            ) : null}
          </div>
        }
        step={1}
        max={50}
        hasInput
        hasLegend
        data-testid="home-address-blur-amount-slider"
        legendSuffix="px"
      />
      <BackgroundOverlayField />
      <div>
        <Toggle labelText={t('microsite.customAddressText.title')} name="usesCustomAddressText" />
        {values.usesCustomAddressText ? (
          <>
            <InlineNotice
              size="small"
              content={t('microsite.customAddressText.howToUse')}
              className="border-[1px] border-solid border-general-blue-tertiary mb-2"
            />
            <RichTextEditorInput
              name="customAddressHtml"
              labelText={
                <span>
                  <span className="mr-0.5">{t('microsite.customAddressText.overrideLabel')}</span>
                  <InfoTooltip
                    className="mb-0.25"
                    tooltipText={t('microsite.customAddressText.overrideTooltip')}
                  />
                </span>
              }
              editorClassName="bg-white"
              hideLabel
              required
              excludeMenuItems={['link', 'bulletList']}
              resetKey={resetKey}
              fast
              shouldDebounce
            />
          </>
        ) : (
          <InlineNotice
            className="border-[1px] border-solid border-general-blue-tertiary"
            size="small"
            content={
              <>
                <p>{t('microsite.customAddressText.explanationTextOne')}</p>
                <p>{t('microsite.customAddressText.explanationTextTwo')}</p>
              </>
            }
          />
        )}
      </div>
    </ContentBlockWrapper>
  );
}
