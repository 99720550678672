import { createMarker, getSymbolPath } from '@shared/googleMaps';
import COLORS, { COLORS_WITHOUT_HASHTAG } from '@shared/colorConstants';
import { BuildingMapPoint } from '@root/types/Building';
import { BuildingSearchResult, Listing, TourbookListing } from '@root/types';

const DEFAULT_STYLES = {
  largeIconScale: 15,
  smallIconScale: 11,
  strokeWeight: 2,
};

const SEARCH_REDESIGN_STYLES = {
  largeIconScale: 13,
  smallIconScale: 11,
  strokeWeight: 1,
};

interface GeneratedMarker extends google.maps.Marker {
  exclusive: boolean;
  label: undefined;
  count: number;
}

export interface TruvaMarkerOptions extends google.maps.MarkerOptions {
  lng: any;
  lat: any;
}

class MarkerGenerator {
  styles: typeof SEARCH_REDESIGN_STYLES | typeof DEFAULT_STYLES;

  hideNumbers: boolean;

  constructor({ shouldUseSearchRedesignStyles = false, hideNumbers = false } = {}) {
    this.styles = shouldUseSearchRedesignStyles ? SEARCH_REDESIGN_STYLES : DEFAULT_STYLES;
    this.hideNumbers = hideNumbers;
  }

  createMarker = ({
    map,
    building,
    isClustered,
    clickable,
  }: {
    map: google.maps.Map;
    building: BuildingMapPoint;
    isClustered: boolean;
    clickable: boolean;
  }) => {
    const marker: GeneratedMarker = createMarker(
      map,
      this.getMarkerOptions(building),
      isClustered,
      clickable,
    ) as GeneratedMarker;
    marker.exclusive = building.exclusive;
    marker.count = building.count;
    return marker;
  };

  createHoveredListingIndicator = (map: google.maps.Map, building) =>
    createMarker(map, this.getMarkerOptions(building), false, false);

  getMarkerColor = (highlighted, exclusive) => {
    if (highlighted) return COLORS_WITHOUT_HASHTAG.indigo90;
    if (exclusive) return COLORS_WITHOUT_HASHTAG.indigo;
    return COLORS_WITHOUT_HASHTAG.indigo110;
  };

  generateMarkerIconUrl = (
    count: number = 1,
    highlighted: boolean = false,
    exclusive: boolean = false,
  ): string => {
    const hasManyListings = count >= 10;
    const textToDisplay = count === 1 || this.hideNumbers ? ' ' : count;

    const baseUrl = 'https://media.truva.com/image/upload';
    const makeCircle = '/r_max';
    const addColor = `/co_rgb:${this.getMarkerColor(highlighted, exclusive)},e_colorize:100`;
    const addBorder = '/e_outline,co_white';
    const addText = `/co_white,l_text:Arial_${
      hasManyListings ? 64 : 76
    }_antialias_good:${textToDisplay}`;

    // This was uploaded directly to cloudinary via the console
    const baseImageCloudinaryIdAndName = '/v1644265903/assets/blank.png';

    return baseUrl + makeCircle + addColor + addBorder + addText + baseImageCloudinaryIdAndName;
  };

  getMarkerIcon = (
    count = 1,
    { highlighted, exclusive } = { highlighted: false, exclusive: false },
  ) => {
    const hasManyListings = count >= 10;
    const diameter = hasManyListings ? 27 : 23;

    return {
      url: this.generateMarkerIconUrl(count, highlighted, exclusive),
      scaledSize: new window.google.maps.Size(diameter, diameter),
      anchor: new window.google.maps.Point(diameter / 2, diameter / 2),
    };
  };

  getHoveredListingIndicatorIcon = (entity: BuildingSearchResult | Listing | TourbookListing) => {
    let count: number | undefined;
    if ('count' in entity) {
      count = entity.count;
      return this.getMarkerIcon(count);
    }

    return {
      path: getSymbolPath().CIRCLE,
      scale: (count ?? 1 > 10) ? this.styles.largeIconScale : this.styles.smallIconScale,
      fillColor: COLORS.indigo90,
      fillOpacity: 1,
      strokeWeight: this.styles.strokeWeight,
      strokeColor: '#FFFFFF',
    };
  };

  getMarkerOptions = (building: BuildingMapPoint): TruvaMarkerOptions => {
    const { longitude, latitude, count, exclusive } = building;

    return {
      lng: longitude,
      lat: latitude,
      icon: this.getMarkerIcon(count, { exclusive, highlighted: false }),
    };
  };

  calculateClusterStyle = markers => {
    let listingCount = 0;
    let exclusive = false;

    markers.forEach(marker => {
      if (marker.exclusive) {
        exclusive = true;
      }
      listingCount += marker.count;
    });

    return { text: listingCount, exclusive };
  };
}

export default MarkerGenerator;
