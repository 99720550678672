import { TextInput } from '@components/shared/forms';
import { Checkbox } from '@viewthespace/components';
import { AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import TextColorSelector from '../../TextColorSelector';
import ColorPicker from '../../ColorPicker';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import { useMicrositeFormContext } from '../../utils';

export default function Location({ id, index, anchorSlug }: ContentBlockProps) {
  const { setFieldTouched, setFieldValue, values } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const sectionTitlesAreRichText = flags['market.building-descriptions-support-rich-text'];
  const { t } = useTranslation('admin');
  const { colorGroups, microsite, resetKey, sendPreviewTo, changeFontColorWithColorChange } =
    useMicrositeFormContext();

  const isLocationSectionHidden = !values.homeGoogleMapSectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.googleMap')
          : t('microsite.sections.homeGoogleMap')
      }
      section="homeGoogleMap"
      onClick={() => sendPreviewTo({ page: 'home', element: anchorSlug })}
      index={index}
      isHidden={isLocationSectionHidden}
    >
      <Checkbox
        label={t('microsite.hideSection')}
        className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
        backgroundStyle="filled"
        isChecked={isLocationSectionHidden}
        onChange={e => {
          setFieldValue('homeGoogleMapSectionIsVisible', !e.value);
          setFieldTouched('homeGoogleMapSectionIsVisible', true);
        }}
      />

      <ColorPicker
        colorGroups={colorGroups}
        name="homeGoogleMapSectionBackgroundColor"
        labelText={t('microsite.homeGoogleMapSectionBackgroundColor.title')}
        onChange={changeFontColorWithColorChange('homeGoogleMapSectionTextColor')}
      />
      {flags['market-office.text-color-selector'] && (
        <TextColorSelector name="homeGoogleMapSectionTextColor" />
      )}
      {sectionTitlesAreRichText ? (
        <RichTextEditorInput
          name="homeGoogleMapSectionTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          excludeMenuItems={['bulletList']}
          initialContent={microsite.homeGoogleMapSectionTitle}
          defaultTextSize="header32"
          resetKey={resetKey}
          fast
          shouldDebounce
        />
      ) : (
        <TextInput name="homeGoogleMapSectionTitle" labelText={t('microsite.sectionTitle')} />
      )}
    </ContentBlockWrapper>
  );
}
