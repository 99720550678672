import { memo } from 'react';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import api from '@shared/api';
import routes from '@root/routes';
import { Select, TextArea } from '@components/shared/forms';
import { AsyncSelect } from '@components/shared/forms/Dropdowns';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Input from './Input';
import css from './styles.module.less';
import CustomIcon from '../../../../shared/CustomIcon';

const fetchCompanies = async (query, page) => {
  const response = await api.fetch(routes.api.admin.companies(query, page));
  const { companies } = await response.json();
  return companies.map(company => ({ label: company.name, value: company.id }));
};

const fetchLandlords = async (query, page) => {
  const response = await api.fetch(routes.api.admin.landlordsList(query, page));
  const { landlords } = await response.json();
  return landlords.map(landlord => ({ label: landlord.name, value: landlord.id }));
};

const searchCompanies = throttle(query => fetchCompanies(query, 1), 250);
const searchLandlords = throttle(query => fetchLandlords(query, 1), 250);

const Details = ({
  owningCompanyId,
  owningCompanyName,
  landlordId,
  landlordName,
  notableTenants,
  rentableBuildingAreaUnit,
  ceilingHeightUnits,
  initialValues,
}) => {
  const { t: adminT } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  const flags = useFlags();

  return (
    <section>
      <h2>{adminT('building.details.title')}</h2>
      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={adminT('building.units')}
          name="units"
          disabled
        />
        <Input
          containerClass={css.right}
          labelText={adminT('building.currency')}
          name="currency"
          disabled
        />
      </div>
      <Select
        className={css.select}
        required
        labelText={adminT('building.ceilingHeightUnits.displayName')}
        data-testid="building-ceiling-height-units"
        name="ceilingHeightUnits"
        isClearable={false}
        options={[
          { label: adminT('building.ceilingHeightUnits.imperial'), value: 'imperial' },
          { label: adminT('building.ceilingHeightUnits.metric'), value: 'metric' },
        ]}
        placeholder=""
        value={ceilingHeightUnits}
      />
      {initialValues.ceilingHeightUnits !== ceilingHeightUnits && (
        <div className={css.warningMessage}>
          <CustomIcon type="exclamation-circle" className={css.warningIcon}></CustomIcon>
          {adminT('building.ceilingHeightUnits.warningMessage')}
        </div>
      )}

      {flags['outreach.landlord-company-connection-severed'] ? (
        <AsyncSelect
          className={css.select}
          data-testid="building-space-provider"
          initialValue={{
            label: landlordName,
            value: landlordId,
          }}
          labelText={adminT('building.spaceProvider')}
          loadOptions={searchLandlords}
          mapValue={v => v?.value}
          name="landlordId"
          placeholder=""
        />
      ) : (
        <AsyncSelect
          className={css.select}
          data-testid="building-space-provider"
          initialValue={{
            label: owningCompanyName,
            value: owningCompanyId,
          }}
          labelText={adminT('building.spaceProvider')}
          loadOptions={searchCompanies}
          mapValue={v => v.value}
          name="owningCompanyId"
          placeholder=""
        />
      )}
      <Input
        containerClass={css.half}
        labelText={adminT('building.numberOfFloors')}
        name="numberOfFloors"
        type="number"
      />
      <div className={css.sideBySide} data-testid="rentable-building-area">
        <Input
          containerClass={css.left}
          labelText={adminT('building.rentableBuildingArea')}
          name="rentableBuildingArea"
          type="number"
        />
        <Select
          className={css.select}
          isClearable={false}
          labelClass={css.invisible}
          labelText={commonT('unit')}
          name="rentableBuildingAreaUnit"
          options={[
            {
              label: adminT('units:SquareFoot'),
              value: 'SquareFoot',
            },
            {
              label: adminT('units:SquareMeter'),
              value: 'SquareMeter',
            },
          ]}
          placeholder=""
          value={rentableBuildingAreaUnit}
          isDisabled
        />
      </div>
      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={adminT('building.yearBuilt')}
          name="yearBuilt"
          type="number"
        />
        <Input
          containerClass={css.right}
          labelText={adminT('building.yearRenovated')}
          name="yearRenovated"
          type="number"
        />
      </div>
      {flags['market.building-descriptions-support-rich-text'] ? (
        <RichTextEditorInput
          name="htmlDescription"
          inputContainerClass={css.richTextEditorContainer}
          labelText={adminT('building.description')}
          hideLabel
          editorClassName="min-h-[100px]"
          initialContent={initialValues.htmlDescription}
          required
          excludeMenuItems={['textSize', 'textAlign']}
          fast
        />
      ) : (
        <TextArea labelText={adminT('building.description')} name="description" />
      )}
      <AsyncSelect
        className={css.select}
        data-testid="notable-tenants"
        initialValue={notableTenants}
        isMulti
        labelText={adminT('building.notableTenants')}
        loadOptions={searchCompanies}
        name="notableTenants"
        mapValue={id => ({ companyId: id })}
        placeholder=""
      />
    </section>
  );
};

export default memo(Details);
