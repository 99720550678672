import { useCallback, useEffect, useMemo } from 'react';
import { FastField, Field } from 'formik';
import cn from 'classnames';
import {
  RichTextEditor,
  RichTextEditorTextSize,
  RichTextEditorToolbarItems,
} from '@viewthespace/components';
import { debounce } from 'lodash';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';

type Props = {
  editorClassName?: string;
  hideLabel?: boolean;
  initialContent?: string | null;
  excludeMenuItems?: RichTextEditorToolbarItems[];
  defaultTextSize?: RichTextEditorTextSize;
  onChange?: (value: string) => void;
  placeholder?: string;
  resetKey?: number;
  shouldDebounce?: boolean;
  debounceTime?: number;
  /**
   * Improves performance, but may cause issues if field is dependent on other fields
   */
  fast?: boolean;
} & Omit<FieldWrapperProps, 'children'>;

const RichTextEditorInput = ({
  name,
  editorClassName,
  inputContainerClass,
  containerClass,
  hideLabel = false,
  initialContent,
  labelText,
  required,
  excludeMenuItems = [],
  defaultTextSize,
  onChange,
  placeholder,
  resetKey = 0,
  fast = false,
  shouldDebounce = false,
  debounceTime = 500,
}: Props) => {
  const FieldComponent = fast ? FastField : Field;
  return (
    <FieldWrapper
      displayError={false}
      {...{ name, labelText, required, inputContainerClass, containerClass }}
    >
      <FieldComponent name={name}>
        {({ field, form, meta }) => (
          <DebouncedRichTextEditorInput
            name={name}
            editorClassName={editorClassName}
            hideLabel={hideLabel}
            initialContent={initialContent}
            excludeMenuItems={excludeMenuItems}
            defaultTextSize={defaultTextSize}
            onChange={onChange}
            placeholder={placeholder}
            resetKey={resetKey}
            field={field}
            form={form}
            meta={meta}
            debounceTime={debounceTime}
            shouldDebounce={shouldDebounce}
          />
        )}
      </FieldComponent>
    </FieldWrapper>
  );
};

type DebouncedRichTextEditorInputProps = {
  name: string;
  editorClassName?: string;
  hideLabel?: boolean;
  initialContent?: string | null;
  excludeMenuItems?: RichTextEditorToolbarItems[];
  defaultTextSize?: RichTextEditorTextSize;
  onChange?: (value: string) => void;
  placeholder?: string;
  resetKey?: number;
  debounceTime?: number;
  shouldDebounce?: boolean;
  field: any;
  form: any;
  meta: any;
};
const DebouncedRichTextEditorInput = ({
  name,
  editorClassName,
  hideLabel = false,
  initialContent,
  excludeMenuItems = [],
  defaultTextSize,
  onChange,
  placeholder,
  resetKey = 0,
  field,
  form,
  meta,
  debounceTime,
  shouldDebounce,
}: DebouncedRichTextEditorInputProps) => {
  const updateFormik = useCallback(
    (value?: string) => {
      field.onChange({ target: { name: field.name, value } });
      form.setFieldTouched(field.name, true, false);
      if (onChange && value) onChange(value);
    },
    [field, form, onChange],
  );

  const debouncedUpdateFormik = useMemo(
    () => debounce(value => updateFormik(value), debounceTime),
    [debounceTime, updateFormik],
  );

  useEffect(() => {
    return () => debouncedUpdateFormik.cancel();
  }, [debouncedUpdateFormik]);

  return (
    <RichTextEditor
      key={`rich-text-editor-${name}-${resetKey}`}
      hideLabel={hideLabel}
      testId="rich-text-editor"
      label="ignore"
      editorClassName={cn(editorClassName, 'selection:!bg-cta-primary-default')}
      name={name}
      errorText={meta.touched && meta.error ? meta.error : undefined}
      initialContent={initialContent || field.value}
      excludeMenuItems={excludeMenuItems}
      defaultTextSize={defaultTextSize}
      placeholder={placeholder}
      onChange={value => {
        if (shouldDebounce) {
          debouncedUpdateFormik(value);
        } else {
          updateFormik(value);
        }
      }}
    />
  );
};

export default RichTextEditorInput;
