import { TextInput } from '@components/shared/forms';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { Checkbox } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormikContext } from 'formik';
import { AdminMicrosite } from '@root/types';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import ColorPicker from '../../ColorPicker';
import TextColorSelector from '../../TextColorSelector';
import { isSinglePageLayout, useMicrositeFormContext } from '../../utils';

export default function Amenities({ id, index, anchorSlug }: ContentBlockProps) {
  const { colorGroups, microsite, resetKey, changeFontColorWithColorChange, sendPreviewTo } =
    useMicrositeFormContext();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();

  const isAmenitiesSectionHidden = !values.amenitiesSectionIsVisible;
  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.amenities')
          : t('microsite.sections.featuresAmenities')
      }
      section="featuresAmenities"
      onClick={() =>
        sendPreviewTo({
          page: isSinglePageLayout(values.layout!) ? 'home' : 'features',
          element: anchorSlug,
        })
      }
      index={index}
      isHidden={isAmenitiesSectionHidden}
    >
      <Checkbox
        label={t('microsite.hideSection')}
        className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
        backgroundStyle="filled"
        isChecked={isAmenitiesSectionHidden}
        onChange={e => {
          setFieldValue('amenitiesSectionIsVisible', !e.value);
          setFieldTouched('amenitiesSectionIsVisible', true);
        }}
      />
      <ColorPicker
        colorGroups={colorGroups}
        name="amenitiesBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        onChange={changeFontColorWithColorChange('amenitiesTextColor')}
      />
      {flags['market-office.text-color-selector'] && (
        <TextColorSelector name="amenitiesTextColor" />
      )}

      {flags['market.building-descriptions-support-rich-text'] ? (
        <RichTextEditorInput
          name="amenitiesTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          excludeMenuItems={['bulletList']}
          initialContent={microsite.amenitiesTitle}
          defaultTextSize="header32"
          resetKey={resetKey}
          fast
          shouldDebounce
        />
      ) : (
        <TextInput name="amenitiesTitle" labelText={t('microsite.sectionTitle')} />
      )}
    </ContentBlockWrapper>
  );
}
